import {createApp} from 'vue'
import App from './App.vue'
import {router} from "@/router/router";

// import { showToast } from 'vant';
import 'vant/es/toast/style';
//
// // Dialog
// import { showDialog } from 'vant';
// import 'vant/es/dialog/style';
//
// // Notify
// import { showNotify } from 'vant';
// import 'vant/es/notify/style';
//
// // ImagePreview
// import { showImagePreview } from 'vant';
// import 'vant/es/image-preview/style';

import axios from 'axios'
import VueAxios from 'vue-axios'


var app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')
